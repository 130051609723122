@font-face{
  font-family: 'mikado';
  src: local('Mikado-Bold'), url(./resources/fonts/Mikado-Bold.otf);
  font-weight: bold;
}

@font-face{
  font-family: 'inter-bold';
  src: local('Inter-Bold'), url(./resources/fonts/Inter-Bold.ttf);
  font-weight: bold;
}

@font-face{
  font-family: 'inter-regular';
  src: local('Inter-Regular'), url(./resources/fonts/Inter-Regular.ttf);
  font-weight: normal;
}
